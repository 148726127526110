<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm8>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        Bulk SMS
                        <v-spacer></v-spacer>
                    </v-card-title>

                    <v-card-text class="pb-1">
                        <v-form ref="form" @submit.prevent="save"
                                @keydown.native="form.errors.clear($event.target.mobile_lists)" @keyup.enter="save"
                                lazy-validation>
                            <input type="file"
                                   @change="onFilePicked($event,'document_back')"
                                   style="display: none"
                                   ref="document_back">

                            <v-btn style="margin-left: 0px" color="success" outlined @click="$refs.document_back.click()">
                                {{fileName ? fileName : 'Upload File (.txt Format Only)' }}
                            </v-btn>
                            <br>
                            <a target="_blank" href="http://sms.sahakaryatech.com/sample/mobile_lists.txt">Sample
                                File</a>
                            <p class="mobile-validation-message" v-if="mobileValidate">Mobile number should be of 10
                                digits</p>
                            <v-select outlined dense :items="templates" class="pa-0 pt-3" label="Select Template"
                                      v-model="template"/>
                            <v-textarea outlined dense autocomplete="off" label="Message*" required
                                        class="pa-0" v-model="form.message" name="rank"
                                        :rows="4"
                                        :error-messages="form.errors.get('message')"/>
                            <p v-if="characterUsed > 0">Character Used :
                                {{characterUsed}}, Parts : {{form.sms_length}}</p>
                        </v-form>

                    </v-card-text>
                    <v-card-actions v-if="smsServiceStatus">
                        <v-spacer></v-spacer>
                        <v-btn color="success" outlined @click="save" :disabled="btnDisable">{{btnText}}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>


            <v-flex xs12 sm4 v-if="smsServiceStatus">
                <v-card>
                    <v-card-title class="title">
                        <v-icon>layers</v-icon> &nbsp;SMS Information
                        <v-spacer></v-spacer>
                    </v-card-title>

                    <v-card-text class="pb-1">
                        <p><strong>Balance : </strong> {{balance.numberFormat()}}</p>
                        <p><strong>Rate Per SMS : </strong> {{rate_per_sms.numberFormat()}}</p>
                        <p><strong>SMS in queue : </strong> {{sms_queue}}</p>

                    </v-card-text>

                </v-card>
            </v-flex>
            <v-flex xs12 sm4 v-else>
                <v-card>
                    <v-card-title class="title">
                        <v-icon>layers</v-icon> &nbsp;SMS Service is not Activated
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text class="pb-1">
                        <p>Please contact our support for activation SMS Service.</p>
                    </v-card-text>
                </v-card>
            </v-flex>


        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import SMS from '@/library/SMS';


    export default {
        data: () => ({
            template: '',
            form: new Form({
                sms_type: 'b',
                msg_type: 1,
                mobile_lists: '',
                message: '',
                sms_length: 1,
                sms_for:"notice"
            }, '/api/sms/queue'),
            templates: [],
            sms: new SMS(),
            characterUsed: 0,
            partsUsed: 0,
            btnDisable: true,
            mobileValidate: false,
            btnText: 'Send Bulk SMS',
            balance: 0,
            sms_queue: 0,
            rate_per_sms: 0,
            fileName: '',
            smsServiceStatus:false,
        }),

        computed: {
            ...mapState(['batch']),
        },

        watch: {
            // 'batch': function (value) {
            //     this.get();
            // },
            // 'form.mobile': function (v) {
            //     if (v.length > 10) {
            //         console.log('testing...');
            //         this.form.mobile = v.substr(0, 10);
            //     }
            //
            // },
            'template': function (v) {
                if (v) {
                    let d = {};
                    d = this.templates.find(res => res.value === v);
                    if (d.text) this.btnDisable = false;
                    this.form.message = d.message;
                }
            },
            'form.message': function (value) {
                if (!value) this.btnDisable = true;
                else this.btnDisable = false;

                let smsDetail = {characters: 0, total_sms: 0};
                if (value) smsDetail = this.sms.calculate(value);
                this.characterUsed = smsDetail.characters;
                this.form.sms_length = smsDetail.total_sms;
                this.form.msg_type = smsDetail.msg_type
            }

        },
        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&');
            },
            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total;
                })
            },

            save() {
                this.form.fireFetch = false;
                this.btnDisable = true;
                this.btnText = 'Processing';
                this.form.store().then(res => {
                    this.getBalance();
                }).catch(e => {
                    this.$events.fire('notification', {message: e.data.message, status: 'error'})

                }).finally(e => {
                    this.btnDisable = false;
                    this.template = '',
                        this.btnText = 'Send Bulk SMS';
                });
            },
            getTemplates() {
                this.$rest.get('/api/sms/template?template_list=true').then(data => {
                    this.templates = data.data.data.map(smsTemp => {
                        return {value: smsTemp.id, text: smsTemp.name, message: smsTemp.message}
                    })
                });
            },
            mobileChange() {
                if (this.form.mobile.length > 10) {
                    this.form.mobile = this.form.mobile.substr(0, 10);
                    this.mobileValidate = true;
                } else {
                    this.mobileValidate = false;
                }

            },
            getBalance() {
                this.$rest.get('/api/sms/dashboard').then(res => {
                    this.balance = res.data.balance;
                    this.sms_queue = res.data.smsqueue;
                    this.rate_per_sms = res.data.rate_per_sms;
                })
            },
            onFilePicked(e) {
                const file = e.target.files[0];
                this.fileName = file.name;
                if (!file.type.includes('text/plain')) {
                    alert('Please uplaod .txt File');
                    this.fileName = '';
                    return;
                } else {
                    this.form.mobile_lists = file;
                }
                // console.log(file);
                // reader.readAsDataURL(file);
            },

        },
        mounted() {
            this.smsServiceStatus = false;
            this.$auth.user().roles.filter(res=> {
                if(res.slug === 'sms-user') this.smsServiceStatus = true;
            })
            this.getTemplates();
            this.getBalance();
        }
    }
</script>
<style lang="scss" scoped>
    .mobile-validation-message {
        font-size: 11px;
        margin-top: -17px;
    }
</style>